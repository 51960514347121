import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  SmileOutlined,
  SyncOutlined,
  HeartOutlined,
  MessageOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

export default function DocumentCards() {
  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/specRecruitment" className="card-link">
            <Card className="document-card first-card">
              <div className="ribbon-content">
                <SmileOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Spec recruiting</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/recruitment-Integration" className="card-link">
            <Card className="document-card second-card">
              <div className="ribbon-content">
                <SyncOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Sync Recruiting</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/onboardingBackground" className="card-link">
            <Card className="document-card third-card">
              <div className="ribbon-content">
                <HeartOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Background checks</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/offerLetters" className="card-link">
            <Card className="document-card fourth-card">
              <div className="ribbon-content">
                <MessageOutlined className="message-icon mb-2" />
                <div className="card-content">
                  <h3>Offer Letters</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/onboarding" className="card-link">
            <Card className="document-card fifth-card">
              <div className="ribbon-content">
                <FilePdfOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Onboarding checklists</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/documentsManagers" className="card-link">
            <Card className="document-card sixth-card">
              <div className="ribbon-content">
                <FileSyncOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Document manager</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/performance" className="card-link">
            <Card className="document-card seventh-card">
              <div className="ribbon-content">
                <FileSyncOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Performance Management</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Link to="/report" className="card-link">
            <Card className="document-card eight-card">
              <div className="ribbon-content">
                <FileSyncOutlined className="icon mb-2" />
                <div className="card-content">
                  <h3>Reports</h3>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
