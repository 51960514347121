import React from "react";
import { Button, Table, Spin, Card, Row, Col } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./OrganizationHierarchy.scss";
import Footer from "Components/authentication/components/Footer";
import Service from "./Service";

function OrganizationHierarchy() {
  const {
    renderJobTitles,
    columns,
    filteredData,
    departmentClickHandler,
    handleBack,
    employeesData,
    buttonText,
    loading,
    departments,
    showTable,
    showEmployeeTable,
  } = Service();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Organization Hierarchy By Job Titles"
        extra={
          <Button type="default" onClick={handleBack}>
            {buttonText}
          </Button>
        }
      >
        {!showTable && !showEmployeeTable && (
          <Row gutter={[16, 16]} justify="center">
            {departments.map((dept, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Card
                  className="document-card"
                  onClick={() => departmentClickHandler(dept.name)}
                  style={{ cursor: "pointer", background: dept.color }}
                >
                  <h3>{dept.name}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        {showTable && (
          <div className="job-titles-table">
            <Table
              columns={columns}
              className="table-responsive"
              dataSource={filteredData}
              rowKey="id"
            />
          </div>
        )}

        {showEmployeeTable && (
          <div className="employee-table">
            {loading ? (
              <div className="loading">
                <Spin />
              </div>
            ) : (
              renderJobTitles(employeesData)
            )}
          </div>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default OrganizationHierarchy;
