import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import payrollimg from "assets/images/payroll.png";
import attendance from "assets/images/attendance.png";
import hiringimg from "assets/images/hiringimg.png";
import salary from "assets/images/salary.jpg";
import skillsReport from "assets/images/skillsReport.png";
import orgHierarchy from "assets/images/orgHierarchy.png";
import traininganddevelopement from "assets/images/traininganddevelopement.png";
import leave from "assets/images/leave.png";
import { useNavigate } from "react-router-dom";
import Footer from "Components/authentication/components/Footer";
import { Card, Row, Col } from "antd";
import '../../Components/global.scss'


const color1 = "linear-gradient(to top, #ff4b5c, #ff8c42)";
const color2 = "linear-gradient(to top, #6a11cb, #2575fc)";
const color3 = "linear-gradient(to top, #ff9a8b, #ff6a00)";
const color4 = "linear-gradient(to top, #2d92d1, #56ccf2)"
const color5 = "linear-gradient(to top, #6a9a8e, #0f3443)"
const color6 = "linear-gradient(to top, #005a80, #0f3443)";
const color7 = "linear-gradient(to top, #4d5aa6, #0f3443)";
const color8 = "linear-gradient(to top, #009dae, #0f3443)";
const color9 = "linear-gradient(to top, #669999, #0f3443)";
const cardData = [
  { img: payrollimg, text: "Expense Management", onClick: "/expenseManagement", color: color1 },
  { img: attendance, text: "Attendance", onClick: "/reportsAttendance", color: color2 },
  { img: leave, text: "Time Off/Leaves", onClick: "/timeOff", color: color3 },
  { img: payrollimg, text: "Salary Slip", onClick: "/salarySlip", color: color4 },
  { img: hiringimg, text: "Hiring", onClick: "/recruitment-Integration", color: color5 },
  {
    img: traininganddevelopement,
    text: "Training and Development",
    onClick: "/enrolled-Employee-for-Training",
    color: color6,
  },
  { img: skillsReport, text: "Skills Report", onClick: "/skillsReport", color: color7 },
  { img: salary, text: "Salary Analysis", onClick: "/salaryAnalysis", color: color8 },
  {
    img: orgHierarchy,
    text: "Organization Hierarchy",
    onClick: "/organizationHierarchy",
    color: color9,
  },
];

function Report() {
  const navigate = useNavigate();
  const handleCardClick = (path) => navigate(path);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card title="Self Services" className="card-professional">
        <Row gutter={[16, 16]} justify="center">
          {cardData.map(({ img, text, onClick, color }, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                className="circular-box"
                onClick={() => handleCardClick(onClick)}
                style={{ background: color }}
              >
                <div className="ribbon-content">
                  <img src={img} alt={text} style={{ height: "60px" }} />
                  <h3>{text}</h3>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Report;
